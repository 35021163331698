
	import VueRendererComponent from "client/src/renderers/vue-components/vue-renderer-component.vue";
	import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
	import ActionButtonRenderer from "client/src/renderers/action-button-renderer/action-button-renderer.vue";

	import { AgGridVue } from "ag-grid-vue";
	import Log from "utils/src/log";
	import _ from 'lodash';
	
	const log = Log.instance("client/renderers/permission-view");

	export default {
		name: "permission-view",
		mixins: [VueRendererComponent],
		components: { VueBootstrapTypeahead, AgGridVue, ActionButtonRenderer },
		data() {
			return {
				permission: {
					id: null,
					properties: {
						name: null
					}
				},
				permissionUsers: {},
				permissionTeams: {},
				allUsers: {},
				allTeams: {},
				userTrash: {},
				teamTrash: {},
				selectedUser: null,
				selectedTeam: null,


				userColumnDefinitions: [],
				teamColumnDefinitions: [],
				frameworkComponents: null,
				defaultColumnDefinitions: {
					sortable: true
				},
				error: undefined,
				success: undefined
			}
		},
		computed: {
			allUsersData() {
				return _.filter(this.allUsers, user => _.isString(_.get(user, 'name')) );
			},
			allTeamsData() {
				return _.filter(this.allTeams, team => _.isString(_.get(team, 'name')) );
			},
			permissionUsersData() {
				return Object.values(this.permissionUsers);
			},
			permissionTeamsData() {
				return Object.values(this.permissionTeams);
			}
		},
		methods: {
			async submit() {
				let result;
				try {
					result = await this.$graphileon.api.savePermission(this.permission);
				} catch (e) {
					this.error = e.message;
					throw e;
				}
				this.$emit('submit', this.permission);
				// Assign after event triggered 
				this.permission = result;
				
				let promises = [];

				// remove user which are already in trash
				_.forEach(this.userTrash, async (user) => {
					promises.push(new Promise(async resolve => {
						try {
							let result = await this.$graphileon.api.removeUserFromPermission({id: this.permission.id, userId: user.id});
							this.$delete(this.permissionUsers, user.id);
							this.$delete(this.userTrash, user.id);
							resolve(result);
						} catch (e) {
							this.error = e.message;
							log.error(e);
						}
					}));
				});

				// remove teams which are already in trash
				_.forEach(this.teamTrash, async (team) => {
					promises.push(new Promise(async resolve => {
						try {
							let result = await this.$graphileon.api.removeTeamFromPermission({id: this.permission.id, teamId: team.id});
							this.$delete(this.permissionTeams, team.id);
							this.$delete(this.teamTrash, team.id);
							resolve(result);
						} catch (e) {
							this.error = e.message;
							log.error(e);
						}
					}));
				});
				
				let addedUsers = _.filter(this.permissionUsers, {new: true});
				// save users which are already in permissionUsers
				_.forEach(addedUsers, async (user) => {
					promises.push(new Promise(async resolve => {
						try {
							let result = await this.$graphileon.api.addUserToPermission({id: this.permission.id, userId: user.id});
							this.permissionUsers[user.id].new = false;
							resolve(result);
						} catch (e) {
							this.error = e.message;
							log.error(e);
						}
					}));
				});
				
				let addedTeams = _.filter(this.permissionTeams, {new: true});
				// save teams which are already in permissionUsers
				_.forEach(addedTeams, async (team) => {
					promises.push(new Promise(async resolve => {
						try {
							let result = await this.$graphileon.api.addTeamToPermission({id: this.permission.id, teamId: team.id});
							this.permissionTeams[team.id].new = false;
							resolve(result);
						} catch (e) {
							this.error = e.message;
							log.error(e);
						}
					}));
				});
				await Promise.all(promises);
				this.success = 'Permissions updated successfully.';
			},
			async remove() {
				let result = await this.$graphileon.viewmanager.confirm(
					this.t('Do you want to delete this permission?'),
					{alertType: 'danger', headerText: this.t('Delete Permission')}
				);
				if ( result ){
					await this.$graphileon.api.removePermission(this.permission.id);
					this.$emit('delete', this.permission);
					this.close();
				}
			},
			addUser() {
				let newUser = _.clone(this.allUsers[this.selectedUser]);
				newUser.new = true;
				this.$set(this.permissionUsers, this.selectedUser, newUser);
				this.$delete(this.userTrash, this.selectedUser);
			},
			addUserToTrash(userId) {
				this.$set(this.userTrash, userId, this.allUsers[userId]);
				this.$delete(this.permissionUsers, userId);
			},
			addTeam() {
				let newTeam = _.clone(this.allTeams[this.selectedTeam]);
				newTeam.new = true;
				this.$set(this.permissionTeams, this.selectedTeam, newTeam);
				this.$delete(this.teamTrash, this.selectedTeam);
			},
			addTeamToTrash(teamId) {
				this.$set(this.teamTrash, teamId, this.allTeams[teamId]);
				this.$delete(this.permissionTeams, teamId);
			},


			generateColumnDefinitions() {
				this.userColumnDefinitions = [
					{ headerName: this.t('Username'), field: 'name', filter: 'agTextColumnFilter', filterParams:{ filterOptions: ['contains'] } },
					{ headerName: this.t('Email Address'), field: 'email', filter: 'agTextColumnFilter', filterParams:{ filterOptions: ['contains'] } },
					{
						headerName: this.t('Action'),
						field: 'id',
						suppressMenu: true,
						filter: false,
						cellRenderer: 'actionButtonRenderer',
						cellRendererParams:{
							icon: 'times',
							label: this.t('Remove'),
							name: 'remove',
							style: 'danger',
							onClick: (data, indx) => {
								this.addUserToTrash(data.data.id);
							}
						},
						width: 100,
						resizable: false,
						suppressSizeToFit: true
					}
				];
				this.teamColumnDefinitions = [
					{ headerName: this.t('Team name'), field: 'name', filter: 'agTextColumnFilter', filterParams:{ filterOptions: ['contains'] } },
					{
						headerName: this.t('Action'),
						field: 'id',
						suppressMenu: true,
						filter: false,
						cellRenderer: 'actionButtonRenderer',
						cellRendererParams:{
							icon: 'times',
							label: this.t('Remove'),
							name: 'remove',
							style: 'danger',
							onClick: (data, indx) => {
								this.addTeamToTrash(data.data.id);
							}
						},
						width: 100,
						resizable: false,
						suppressSizeToFit: true
					}
				];
			},
			getRowNodeId(data) {
				return data.id;
			},
			onUserGridReady(params) {
				this.$userGridApi = params.api;
				this.$userColumnApi = params.columnApi;
				this.$userLoadGridAPI.resolve(this.$userGridApi);
			},
			onTeamGridReady(params) {
				this.$teamGridApi = params.api;
				this.$teamColumnApi = params.columnApi;
				this.$teamLoadGridAPI.resolve(this.$teamGridApi);
			},
			onResize() {
				if(this.$teamGridApi) {
					this.$teamGridApi.sizeColumnsToFit();
				}
				if(this.$userGridApi) {
					this.$userGridApi.sizeColumnsToFit();
				}
			}
		},
		mounted() {
			this.generateColumnDefinitions();
		},
		created() {
			this.$userLoadGridAPI = {};
			this.$userLoadGridAPI.promise = new Promise((resolve, reject)=> {
				this.$userLoadGridAPI.resolve = resolve;
				this.$userLoadGridAPI.reject = reject;
			});
			this.$teamLoadGridAPI = {};
			this.$teamLoadGridAPI.promise = new Promise((resolve, reject)=> {
				this.$teamLoadGridAPI.resolve = resolve;
				this.$teamLoadGridAPI.reject = reject;
			});
		},
		beforeMount(){
			this.frameworkComponents = {
				actionButtonRenderer: ActionButtonRenderer
			};
		}
	}
