
	import VueViewComponent from "client/src/vue-view/vue-view-component.vue";
	import {AgGridVue} from "ag-grid-vue";
	import APIClientAbstract from "core/src/api-client-abstract";
	import Log from "utils/src/log";

	import _ from 'lodash';
	import ViewToolbar from "client/src/vue-view/view-toolbar.vue";
	import ViewToolbarButton from "client/src/vue-view/view-toolbar-button.vue";
	import ViewMessages from "client/src/vue-view/view-messages.vue";

	import ContextMenu from 'client/src/vue-view/context-menu.vue';
	import ContextMenuItem from "client/src/vue-view/context-menu-item.vue";

	import Err from "utils/src/error";
	import Language from "core/src/language";
	import ISession from "core/src/i-session";
	import ViewCommon from "client/src/vue-view/view-common.vue";
	import Edition from "core/src/edition";

	const DEFAULT_LANGUAGES = ['en'];
	const DEFAULT_NAMESPACES = ['graphileon-logic'];

	const log = Log.instance("client/view/translate");

	export default {
		name: "translate-view",
		mixins: [VueViewComponent],
		components: {ViewCommon, ViewMessages, ViewToolbarButton, ViewToolbar, AgGridVue, ContextMenu, ContextMenuItem},
		data() {
			return {
				hasPE: true,
				model: {
					languages: undefined,
					languagesRTL: undefined,
					namespaces: undefined,
					namespacesEditable: undefined,
					enableAddTranslation: true,
					translations: [],
					hasChanges: undefined
				},
				modified: {
					added: {},
					changed: {},
					deleted: {}
				},
				columnDefinitions: [],
				defaultColumnDefinitions: {
					sortable: true,
					resizable: true,
					floatingFilter: true
				}
			}
		},
		computed: {
			validLanguages() {
				return this.check(this.model.languages, 'array', 'languages', DEFAULT_LANGUAGES);
			},
			addNewButtonEnabled() {
				return this.check(this.model.enableAddTranslation, 'boolean', 'enableAddTranslation', true);
			},
			validNamespacesEditable() {
				return this.check(this.model.namespacesEditable, 'array', 'namespacesEditable', DEFAULT_NAMESPACES);
			}
		},
		watch: {
			validLanguages: {
				handler(newValue, oldValue) {
					// no idea why this handler is called when the values are equal, but it happens
					if(newValue === oldValue) return;

					this.generateColumnDefinitions();
				}
			},
			validNamespacesEditable: {
				handler(newValue, oldValue) {
					// no idea why this handler is called when the values are equal, but it happens
					if(newValue === oldValue) return;

					this.generateColumnDefinitions();
				}
			},
			'model.namespaces': {
				handler(newValue, oldValue) {
					// no idea why this handler is called when the values are equal, but it happens
					if(newValue === oldValue) return;

					this.fetchTranslations().catch(e => log.error("Could not fetch translations."));
				}
			}
		},
		methods: {
			generateColumnDefinitions() {
				const editable = (rowNode) => rowNode.data.editable;
				this.columnDefinitions = [
					{
						headerName: 'Key',
						field: 'key',
						editable,
						sort: 'asc',
						filter: 'agTextColumnFilter',
						valueSetter: params => {
							if(params.newValue === params.oldValue) return false;

							const newKey = params.newValue;
							const namespace = params.data.namespace;
							if(this.keyExists(newKey, namespace, params.data)) {
								this.showMessage('error:key-exists', 'danger', this.t(`key-exists-in-namespace`, {key: newKey, namespace}));
								return false;
							}

							this.editTranslation({...params.data}, {...params.data, key: params.newValue});
							return true;
						}
					},
					..._.map(this.validLanguages, language => {
						const rtl = !_.isNil(this.model.languagesRTL) && _.includes(this.model.languagesRTL, language);
						return {
							headerName: language,
							field: language,
							editable,
							filter: 'agTextColumnFilter',
							cellStyle: {direction: rtl ? 'rtl' : 'ltr', 'text-align': rtl ? 'right' : 'left'},
							valueSetter: params => {
								if (params.newValue === params.oldValue) return false;
								this.editTranslation({...params.data}, {
									...params.data,
									[params.colDef.field]: params.newValue
								});
								return true;
							}
						};
					}),
					{ headerName: 'Read-Only', field: 'readOnly', filter: 'agTextColumnFilter' },
					{
						headerName: 'Namespace',
						field: 'namespace',
						filter: 'agTextColumnFilter',
						editable,
						cellEditor: "agSelectCellEditor",
						cellEditorParams: params => ({
							values: this.validNamespacesEditable
						}),
						valueSetter: params => {
							if(params.newValue === params.oldValue) return false;

							const newNamespace = params.newValue;
							const key = params.data.key;
							if(this.keyExists(key, newNamespace, params.data)) {
								this.showMessage('error:key-exists', 'danger', this.t(`key-exists-in-namespace`, {key: key, namespace: newNamespace}));
								return false;
							}

							this.editTranslation({...params.data}, {...params.data, namespace: params.newValue});
							return true;
						}
					}
				];
			},

			getRowId(row) {
				return this.getRowNodeId(row.data || {});
			},

			getRowNodeId(rowData) {
				return _.get(rowData, 'namespace', 'unknown') + "__" + _.get(rowData, 'key', 'unknown');
			},
			getParameters() {
				return {
					languages: undefined,
					namespaces: undefined
				}
			},
			getRowClass(params) {
				const classes = [];
				if(params.node.data.added) classes.push('added');
				if(params.node.data.changed) classes.push('changed');
				if(params.node.data.deleted) classes.push('deleted');
				if(params.node.data.changedRemote) classes.push('changed-remote');
				return classes.join(' ');
			},
			postSortRows(params) {
				// put added rows on top while preserving the sort order
				let nextInsertPos = 0;
				for (let i = 0; i < params.nodes.length; i++) {
					const added = params.nodes[i].data.added;
					if (added) {
						params.nodes.splice(nextInsertPos, 0, params.nodes.splice(i, 1)[0]);
						nextInsertPos++;
					}
				}
			},
			keyExists(key, namespace, ignore = undefined) {
				return !!_.find(this.model.translations, row => {
					return row.key === key && row.namespace === namespace && row !== ignore;
				});
			},
			async adjustGrid() {
				await this.$loadGridAPI.promise;
				setTimeout(()=>{
					this.autoSizeColumns();
					this.$gridApi.sizeColumnsToFit();
				});
			},
			autoSizeColumns() {
				this.$columnApi.autoSizeColumns(['key', 'readOnly', 'namespace']);
			},
			async loadTranslations() {
				try {
					this.modified.added = {};
					this.modified.changed = {};
					this.modified.deleted = {};
					this.model.translations = await this.fetchTranslations();
					this.adjustGrid();
				} catch(e) {
					this.setError('load-translations', this.t("Failed to load translations."));
					log.error(e);
				}
			},
			findRowDataIndex(id) {
				let foundIndex;
				const row = _.find(this.model.translations, (translation, index) => {
					if(this.getRowNodeId(translation) === id) {
						foundIndex = index;
						return true;
					}
					return false;
				});
				if(!!row) {
					return foundIndex;
				}
				return null;
			},
			applyMutations(mutations) {
				let newTranslations = _.clone(this.model.translations);

				_.forEach(mutations.delete, translation => {
					const index = this.findRowDataIndex(this.getRowNodeId(translation));
					if(index !== null) {
						newTranslations.splice(index, 1);
					}
				});
				// Combine add and change, should be more robust in case of missing translations
				_.forEach(_.concat(mutations.change, mutations.add), translation => {
					const index = this.findRowDataIndex(this.getRowNodeId(translation));
					if(index !== null) {
						this.$set(newTranslations, index, translation);
					} else {
						newTranslations.push(translation);
					}
				});

				this.model.translations = newTranslations;
			},
			async fetchTranslations() {
				const rows = [];
				const namespaces = await this.$api.getTranslationDefinitions();

				// Transform namespaces into rows
				_.forEach(namespaces, (namespaceTranslations, namespace) => {
					if(!_.isNil(this.model.namespaces) && !_.includes(this.model.namespaces, namespace)) return;

					_.forEach(namespaceTranslations, (entries, key) => {
						const editable = this.validNamespacesEditable.indexOf(namespace) >= 0;
						rows.push({
							key,
							namespace,
							editable,
							readOnly: !editable ? 'read-only' : '',
							...entries
						});
					});
				});
				return rows;
			},
			addTranslation() {
				let emptyRow = _.find(this.model.translations, (row) => ! _.size(row.key));
				if (emptyRow) {
					setTimeout(()=>this.$gridApi.ensureNodeVisible(emptyRow));
					return;
				}

				const namespace = this.validNamespacesEditable[0];
				const newRow = {namespace, editable: true, added: true};
				this.model.translations = _.concat([newRow], this.model.translations);
				this.modified.added[this.getRowNodeId(newRow)] = newRow;

				setTimeout(()=>this.$gridApi.ensureNodeVisible(newRow));

				this.setChanged();
			},
			deleteTranslation(row) {
				// I couldn't find any better way in AgGrid to remove the current row, since a RowNode doesn't provide
				// any index that is consistent despite any sorting settings.
				for(let i = 0; i < this.model.translations.length; i++) {
					const checkRow = this.model.translations[i];
					if(row === checkRow) {
						const id = this.getRowNodeId(checkRow);

						if(id in this.modified.changed) {
							delete this.modified.changed[id];
							delete row.changed;
						}
						if(id in this.modified.added) {
							delete this.modified.added[id];
							delete row.added;
						}
						row.deleted = true;
						row.editable = false;
						this.modified.deleted[id] = row;

						// Notify AgGrid of the change
						const rowNode = this.$gridApi.getRowNode(id);
						rowNode.setData(row);

						this.setChanged();
						return;
					}
				}
			},
			cleanRow(row) {
				return _.omit(row, ['added', 'changed', 'deleted', 'editable', 'sortable', 'changedRemote']);
			},
			async saveTranslations() {
				this.$gridApi.stopEditing(false); // commit current changes from editing field

				// Transform rows into namespaces
				const mutations = {
					add: _.map(_.filter(this.modified.added, (row) => _.size(row.key)), row => this.cleanRow(row)),
					change: _.map(this.modified.changed, row => this.cleanRow(row)),
					delete: _.map(this.modified.deleted, row => this.cleanRow(row))
				};

				try {
					await this.$api.saveTranslationDefinitions(mutations, this.$io.id);
					this.showMessage('save:success', 'success', this.t("Translations saved."));
					this.setChanged(false);
				} catch(e) {
					this.showMessage('save:error', 'danger', new Err(this.t("Could not save translations"), e));
					return;
				}
				try {
					await this.reloadTranslations();
				} catch(e) {
					this.showMessage('reload:error', 'danger', new Err(this.t("Could not reload translations"), e));
				}
			},
			async reloadTranslations() {
				await this.$language.reloadTranslations();
				return this.loadTranslations();
			},
			getContextMenuItems(params) {
				return [{
					name: this.t('Delete'),
					icon: '<i class="fa fa-times"></i>',
					action: () => {
						this.model.translations.splice(params.node.childIndex, 1)
						this.model.translations = _.clone(this.model.translations);
						this.setChanged();
					}
				}];
			},
			setChanged(changed = true) {
				this.model.hasChanges = changed;
			},
			editTranslation(oldData, newData) {
				// Find old row, replace with new one
				const oldId = this.getRowNodeId(oldData);
				const newId = this.getRowNodeId(newData);
				const index = this.findRowDataIndex(oldId);

				this.model.translations.splice(index, 1, newData);
				this.model.translations = _.clone(this.model.translations);

				// Record modification

				this.modified.added = _.omit(this.modified.added, oldId);
				this.modified.deleted = _.omit(this.modified.deleted, oldId);
				this.modified.changed = _.omit(this.modified.changed, oldId);

				delete newData.changedRemote;

				if(oldData.added || oldId !== newId) {
					// Translation was added or replaces an item that was added
					delete newData.changed;
					newData.added = true;
					this.$set(this.modified.added, newId, newData);
				} else {
					// Translation was changed
					if(!newData.added) {
						newData.changed = true;
					}
					this.$set(this.modified.changed, newId, newData);
				}
				// Translation changed original ID, deleted original
				if(!oldData.added && !oldData.changed && oldId !== newId) {
					this.$set(this.modified.deleted, oldId, oldData);
				}

				// Wait for grid update and show new row
				setTimeout(()=>this.$gridApi.ensureNodeVisible(newData));

				this.setChanged();
			},
			onGridReady(params) {
				this.$gridApi = params.api;
				this.$columnApi = params.columnApi;
				this.$loadGridAPI.resolve(this.$gridApi);
			},
			// onFirstDataRendered() {
			// },
			onForceUpdate() {
				this.adjustGrid();
			},
			onCellValueChanged(event) {
				if(!event.data.added) {
					event.data.changed = true;
				}
				this.setChanged();
			},
			onCellContextMenu(params) {
				this.openContextMenu(
					'translation',
					params.event,
					{ row: params.node.data }
				);
			},
			onExternalChange(event) {
				const origin = event.origin;
				const mutations = event.mutations;

				if(_.get(origin, 'originID') !== this.$io.id) {
					this.showMessage('remote:reloaded', 'info', this.t('translations-changed'));

					this.applyMutations({
						add: _.map(mutations.add, row => ({...row, changedRemote: true, editable: true})),
						change: _.map(mutations.change, row => ({...row, changedRemote: true, editable: true})),
						delete: mutations.delete
					});
				}
			}
		},
		created() {
			this.$loadGridAPI = {};
			this.$loadGridAPI.promise = new Promise((resolve, reject)=> {
				this.$loadGridAPI.resolve = resolve;
				this.$loadGridAPI.reject = reject;
			});

			if (!Edition.hasFeature(Edition.Feature.MULTI_LINGUAL)) {
				this.hasPE = false;
				log.error("Your current product and/or license does not support TranslateView");
			}
		},
		mounted() {
			this.$api = this.$dependencies.get(APIClientAbstract);
			this.$language = this.$dependencies.get(Language);
			this.loadTranslations();
			this.generateColumnDefinitions();
			this.defineContextMenu('translation', this.$refs.translationContextMenu);

			this.$io = this.$api.io('/language');
			this.$io.on('connect', ()=>{
				log.debug("Socket ID: ", this.$io.id);
			});
			this.$io.on('disconnect', ()=>{
				log.debug("Socket disconnected");
			});

			this.$translationsChangedListener = this.onExternalChange.bind(this);
			this.$io.on('translations-changed', this.$translationsChangedListener);
		},
		destroyed() {
			if(!this.$io) {
				return; // nothing to do here
			}
			this.$io.off('translations-changed', this.$translationsChangedListener);
			this.$io.close()
		}
	}
