
	import {AgGridVue} from "ag-grid-vue";

	import VueRendererComponent from "client/src/renderers/vue-components/vue-renderer-component.vue";
	import ActionButtonRenderer from "client/src/renderers/action-button-renderer/action-button-renderer.vue";
	import {extend, forEach, get, isArray, map, values, debounce} from 'lodash';
	import "ag-grid-community/styles/ag-grid.css";
	import "ag-grid-community/styles/ag-theme-balham.css";
	import Log from "utils/src/log";
	import Icon from 'client/src/utils/icon';

	const Utils = require('core/src/utils');

	const log = Log.instance("client/function/view/ag-grid");

	require('./ag-grid.scss');

	export default {
		name: "ag-grid-component",

		mixins: [VueRendererComponent],

		components: {
			'ag-grid-vue': AgGridVue,
			// wrong but kept for backwards compatibility 
			'action-button-renderer': ActionButtonRenderer,
			'actionButtonRenderer': ActionButtonRenderer
		},

		data: function() {
			let data = {
				gridApi: undefined,
				loadGridAPI: {},
				data: [],
				state: {
					selected: []
				},
				options: {},
				sizeColumnsToFit: false,
				resizeColumnsToFit: false,
				autoSizeColumns: false,
				contextMenus: {},
				context: null,
				hiddenColumns: [],
				frameworkComponents: null,
				agContextMenu: false,
				updatingSelection: {resolve:()=>{}, reject:()=>{}},
				preventEvent: {},
				agGridLicenseLoaded: false,
				onSelectionChangedDebounced: debounce(this.onSelectionChanged.bind(this))
			};
			data.loadGridAPI.promise = new Promise((resolve, reject)=> {
				data.loadGridAPI.resolve = resolve;
				data.loadGridAPI.reject = reject;
			});
			return data;
		},
		beforeMount(){
			this.context = { componentParent: this };
			this.frameworkComponents = {
				actionButtonRenderer: ActionButtonRenderer
			};

			// Get AgGrid license
			this.$graphileon.requireScript(`/api/license/ag-grid.js?2.2.0`, true).then(()=>{
				this.agGridLicenseLoaded = true;

				if (! window.agGridLicense) {
					log.info("No AgGrid license provided. Using community edition.");
					return;
				}

				let {LicenseManager} = require('ag-grid-enterprise');
				// agGrid-enterprise will display an error banner if the license is invalid
				LicenseManager.setLicenseKey(window.agGridLicense);
				log.info("AgGrid Enterprise license loaded.");
			}).catch(()=>{
				this.agGridLicenseLoaded = true;
				log.info("Could not load the AgGrid license. Using community edition.");
			});
		},
		computed: {
			columnDefs: function() {
				if(!('columnDefs' in this.options)) {
					return this.generateColumnDefs(this.data);
				}

				return _.filter(values(this.options.columnDefs), row => ! _.includes(this.hiddenColumns, row.field) );
			}
		},

		watch: {
			data(data) {
				// AgGrid has a tendency to resize columns after data change
				setTimeout(() => { // wait for data to be added to grid
					this.onResize();
				});
				this.setRowSelection(data);
			}
		},

		methods: {
			setRowSelection: async function(data) {
				try {
					let gridAPI = await this.loadGridAPI.promise;
					gridAPI.forEachNode(node => {
						node.setSelected(node.data._selected);
					});
				} catch(e){
					// should be handled somewhere else
				}
			},
			generateColumnDefs: function(data) {
				let columns = new Set();
				forEach(data, row => {
					forEach(row, (value, column) => {
						columns.add(column);
					})
				});
				
				columns = _.difference([...columns], this.hiddenColumns);
				let columnDefs = [];
				columns.forEach(column => {
					if(column.substr(0,1) === '_' || column === 'visible') return; // Skip underscored columns and 'visible'

					let columnDef = {headerName: column, field: column};
					if(column === 'id') columnDef.hide = true; // by default, hide id
					columnDefs.push(columnDef);
				});
				return columnDefs;
			},
			onGridReady(params) {
				this.gridApi = params.api;
				this.columnApi = params.columnApi;
				this.loadGridAPI.resolve(this.gridApi);

				if (this._data.sizeColumnsToFit || this._data.resizeColumnsToFit) {
					params.api.sizeColumnsToFit();
				}

				if (this._data.autoSizeColumns) {
					this.autoSizeAllColumns();
				}
			},
			onResize() {
				if((this.sizeColumnsToFit || this.resizeColumnsToFit) && this.gridApi) {
					this.gridApi.sizeColumnsToFit();
				}
			},
			getRowNodeId(data) {
				return data.id;
			},

			getContextMenuItems(params) {
				this.agContextMenu = true;

				let menu = [];

				// Custom menu items
				let definition = get(this.contextMenus, 'cell', {});
				for(let action in definition) {
					menu.push({
						name: definition[action].action,
						action: () => {
							this.$emit('context', 'cell', definition[action], {
								data: params.node.data,
								row:  params.node.data,
								__warning: 'The "row" property of this event is deprecated and will be removed in future versions. Please use "data"',
								cell: params.value
							})
						},
						index: definition[action].index,
						tooltip: definition[action].tooltip,
						disabled: definition[action].enable === false || definition[action].disabled,
						icon: '<i class="' + Icon.getClass(definition[action].icon) + '"/>'
					});
				}
				menu = menu.sort((a,b) => Utils.compareIndex(a.index, b.index));

				// Default menu items
				menu.push('copy', 'export');

				return menu;
			},

			// Event handling

			/**
			 * Prevents one or multiple events next time they occur.
			 * @param {Array|string} types 	(Array of) event type string(s) of the event(s) that should be prevented
			 * 							next time they occur.
			 */
			preventNextEvents(types) {
				if(!isArray(types)) types = [types];
				forEach(types, type => {
					this.preventEvent[type] = true;
				});
			},
			/**
			 * Check if an event is currently on the prevented events list.
			 * @param {AgEvent} event
			 */
			isEventPrevented(event) {
				return this.preventEvent[event.data.type] === true;
			},
			/**
			 * Consider the given event prevented, and remove it from the prevented events list.
			 * @param {AgEvent} event
			 */
			eventPrevented(event) {
				delete this.preventEvent[event.data.type];
				return false;
			},
			/**
			 * In case there is a selection update pending, return its promise. Will return an empty, resolved promise
			 * otherwise.
			 *
			 * @returns {Promise}
			 */
			awaitSelectionUpdate() {
				if(this._selectionUpdatePromise !== null) {
					return this._selectionUpdatePromise;
				}
				return Promise.resolve(undefined);
			},
			/**
			 * Trigger event in the Function, based on the given AgEvent.
			 * @param {AgEvent} event
			 */
			triggerEvent(event) {
				if(!(event instanceof AgEvent)) {
					throw new Error("Event should be instance of AgEvent.");
				}
				this.trigger(event.getData());
			},

			async onClickEvent(event) {
				if(this.isEventPrevented(event)) {
					return this.eventPrevented(event);
				}
				// Wait for onSelectionChanged to be called (or not)
				setTimeout(async () => {
					// Don't fire event if:
					if(
						// you can select by clicking a row and multiple rows are selected (you should only do selection)
						(! this.options.suppressRowClickSelection && this.gridApi.getSelectedRows().length > 1)
						|| this.gridApi.getEditingCells().length > 0 	// something is being edited
					) {
						return;
					}

					// Wait for selection update
					await this.awaitSelectionUpdate();
					this.triggerEvent(event);
				});
			},
			onRowClickEvent(event) {
				this.onClickEvent(new RowEvent(event));
			},
			onCellClickEvent(event) {
				this.onClickEvent(new CellEvent(event));
			},
			onCellContextMenu(agEvent) {
				agEvent.event.preventDefault();

				// First give built-in menu a chance
				setTimeout(() => {
					// If ag-Grid's built-in context menu was already rendered, don't render custom one.
					if(this.agContextMenu) return;

					let cellEvent = new CellEvent(agEvent);
					let target = cellEvent.getData();
					delete target.type; // not necessary for context menu target
					this.openContextMenu('cell', target, agEvent.event.x, agEvent.event.y);
				});
			},
			onSelectionChanged() {
				// Store the promise so other events can wait for the new selection state before they fire
				this._selectionUpdatePromise = new Promise((resolve, reject) => {
					// Wait for grid state to update first
					setTimeout(async () => {
						// Don't fire if user just started editing
						if(this.gridApi.getEditingCells().length > 0) return;

						let selection = this.gridApi.getSelectedRows();
						try {
							await this.requestUpdate({'state.selected': selection});
							this.trigger({
								type: 'selectionChanged',
								selection: selection
							});

							// Other events depending on selection can now do their thing
							resolve(selection);
						} catch(e) {
							reject(e);
						}
					});
				});
			},
			onFilterChanged(event) {
				if (event.columnApi.isPivotMode()) {
					this.trigger({
						type: 'pivotSetupChanged',
						columns: event.columnApi.getColumnState(),
						filters: event.api.getFilterModel()
					});
				}

				// Wait for grid state to update first
				setTimeout(
					() => {
						let visibleRows = map(this.getVisibleRows(), (row) => row.data);

						this.requestUpdate({'state.visible': visibleRows});
					}
				);
			},
			async onCellValueChanged(event) {
				await this.updateModel(); // wait for the model to update before sending out a Trigger event
				this.triggerEvent(new CellValueChangedEvent(event));
			},
			async onRowDragEnter(event) {
				await this.updateModel();
				this.triggerEvent(new RowDragEvent(event));
				this.preventNextEvents(['cellClicked', 'rowClicked']);
			},
			async onRowDragLeave(event) {
				await this.updateModel();
				this.triggerEvent(new RowDragEvent(event));
				this.preventNextEvents(['cellClicked', 'rowClicked']);
			},
			async onRowDragEnd(event) {
				await this.updateModel();
				this.triggerEvent(new RowDragEvent(event));
				this.preventNextEvents(['cellClicked', 'rowClicked']);
			},

			async onGridColumnsChanged(event) {
				if (event.columnApi.isPivotMode()) {
					this.trigger({
						type: 'pivotSetupChanged',
						columns: event.columnApi.getColumnState(),
						filters: event.api.getFilterModel()
					});
				}
			},

			/**
			 * Update the AgGridView Function model based on the current state of the AgGrid.
			 * @returns {Promise}	Promise that will resolve once the update was successfully processed.
			 */
			updateModel() {
				let data = [];
				this.gridApi.forEachNode((node) => {
					data.push(node.data);
				});
				return this.requestUpdate({data});
			},

			getVisibleRows() {
				let visibleRows = [];

				this.gridApi.forEachNodeAfterFilter((node) => {
					visibleRows.push(node);
				});

				return visibleRows;
			},

			autoSizeAllColumns() {
				var allColumnIds = [];
				this.columnApi.getAllColumns().forEach((column) => {
					allColumnIds.push(column.colId);
				});
				this.columnApi.autoSizeColumns(allColumnIds);
			}
		}
	}

	class AgEvent {
		/**
		 * @param event
		 * @param [cleanData]	Clean the data from classes and circular references.
		 */
		constructor(event) {
			this.data = {};
			this.setData({
				type: event.type
			});
		}

		setData(data) {
			extend(this.data, data);
		}

		getData() {
			return this.data;
		}
	}

	class RowEvent extends AgEvent {
		constructor(event) {
			super(event);
			this.setData({
				data: event.data,
				rowIndex: event.rowIndex
			});
		}
	}
	class CellEvent extends RowEvent {
		constructor(event) {
			super(event);
			this.setData({
				value: event.value,
				column: get(event, 'column.colId')
			});
		}
	}
	class CellValueChangedEvent extends CellEvent {
		constructor(event) {
			super(event);
			this.setData({
				oldValue: event.oldValue,
				newValue: event.newValue
			});
		}
	}
	class RowDragEvent extends AgEvent {
		constructor(event) {
			super(event);
			this.setData({
				data: event.node.data,
				over: get(event, 'overNode.data'),
				overIndex: event.overIndex,
			});
		}
	}

